html {
  font: 1em Helvetica, sans-serif;
  text-size-adjust: none;
  min-height: 100%;
  background-image: linear-gradient(#b4bcbf, #fff);
  background-repeat: no-repeat;
}

/* Apple's Dynamic Type requires this font family to be used
   Only target iOS/iPadOS */
@supports (font: -apple-system-body) and (-webkit-touch-callout: default) {
  html {
    font: -apple-system-body; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  }
}

html.experimental-features {
  background-size: 40px 40px, 40px 40px,auto;
  background-image:
    linear-gradient(to right, grey .5px, transparent .5px),
    linear-gradient(to bottom, grey .5px, transparent .5px),
    linear-gradient(#112ed2, #a0aceb);
  background-color: #a0aceb;
  background-repeat: repeat, repeat, no-repeat;
}

input, button {
  font-size: .8333rem;
}

body {
  font-size: 0.875em;
  border: none;
  padding: 0;
  margin: 0;
  color: #333;
}

body > * {
  transition: visibility 0.3s ease, opacity 0.3s ease;
}

body.uninit > * {
  visibility: hidden;
  opacity: 0;
}

@keyframes dramatic-entry {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 50% {
    transform: translateX(0) translateY(0);
  }

  5%, 15%, 25%, 35%, 45% {
    transform: translateX(-7.07px);
  }

  7.5%, 17.5%, 27.5%, 37.5% {
    transform: translateY(-7.07px);
  }

  10%, 20%, 30%, 40% {
    transform: translateX(7.07px);
  }

  12.5%, 22.5%, 32.5%, 42.5% {
    transform: translateY(7.07px);
  }
}

/*
<i> cloud, design by daneden

http://dabblet.com/gist/2945570
https://gist.github.com/2945570
*/
.cloud {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background-color: #fff;
  margin: -75px -225px;
  width: 450px;
  height: 150px;
  border-radius: 150px;
  box-shadow: inset 0 -10px rgb(255 255 255 / 60%), 0 20px 20px rgb(0 0 0 / 10%), inset 0 -20px 20px rgb(0 100 160 / 10%);
  transition: box-shadow 0.5s ease, background-color 0.5s ease, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 20;
}

.cloud::before,
.cloud::after {
  box-shadow: 0 -20px 20px rgb(0 0 0 / 5%);
  content: '';
  position: absolute;
  background-color: #fff;
  border-radius: 100%;
  transition: box-shadow 0.5s ease, background-color 0.5s ease;
}

.cloud::before {
  width: 31.1111%;
  height: 93.3333%;
  top: -40%;
  left: 15.5556%;
}

.cloud::after {
  width: 51.1111%;
  height: 153.3333%;
  top: -73.3333%;
  right: 13.3333%;
}

.cloud:has(:focus-visible),
body.dragover .cloud {
  box-shadow: inset 0 -10px rgb(255 255 255 / 60%), 0 10px 30px 10px rgb(255 255 192 / 80%), inset 0 -20px 20px rgb(0 100 160 / 10%);
  background-color: #fffff8;
}

@media not all and (hover: none) {
  .cloud:hover {
    box-shadow: inset 0 -10px rgb(255 255 255 / 60%), 0 10px 30px 10px rgb(255 255 192 / 80%), inset 0 -20px 20px rgb(0 100 160 / 10%);
    background-color: #fffff8;
  }
}

.cloud:has(:focus-visible)::before,
.cloud:has(:focus-visible)::after,
body.dragover .cloud::before,
body.dragover .cloud::after {
  box-shadow: 0 -20px 20px rgb(255 255 192 / 80%);
  background-color: #fffff8;
}

@media not all and (hover: none) {
  .cloud:hover::before,
  .cloud:hover::after {
    box-shadow: 0 -20px 20px rgb(255 255 192 / 80%);
    background-color: #fffff8;
  }
}

@media (width >= 520px) and (width <= 800px) {
  .cloud {
    margin: -56.25px -168.75px;
    width: 337.5px;
    height: 112.5px;
    border-radius: 112.5px;
    box-shadow: inset 0 -7.5px rgb(255 255 255 / 60%), 0 15px 15px rgb(0 0 0 / 10%), inset 0 -15px 15px rgb(0 100 160 / 10%);
  }

  .cloud::before,
  .cloud::after {
    box-shadow: 0 -15px 15px rgb(0 0 0 / 5%);
  }

  .cloud:hover,
  body.dragover .cloud {
    box-shadow: inset 0 -7.5px rgb(255 255 255 / 60%), 0 7.5px 22.5px 7.5px rgb(255 255 192 / 80%), inset 0 -15px 15px rgb(0 100 160 / 10%);
  }

  .cloud:hover::before,
  .cloud:hover::after,
  body.dragover .cloud::before,
  body.dragover .cloud::after {
    box-shadow: 0 -15px 15px rgb(255 255 192 / 80%);
  }
}

@media (width <= 520px) {
  .cloud {
    margin: -37.5px -112.5px;
    width: 225px;
    height: 75px;
    border-radius: 75px;
    box-shadow: inset 0 -5px rgb(255 255 255 / 60%), 0 10px 10px rgb(0 0 0 / 10%), inset 0 -10px 10px rgb(0 100 160 / 10%);
  }

  .cloud::before,
  .cloud::after {
    box-shadow: 0 -10px 10px rgb(0 0 0 / 5%);
  }

  .cloud:hover,
  body.dragover .cloud {
    box-shadow: inset 0 -5px rgb(255 255 255 / 60%), 0 5px 15px 5px rgb(255 255 192 / 80%), inset 0 -10px 10px rgb(0 100 160 / 10%);
  }

  .cloud:hover::before,
  .cloud:hover::after,
  body.dragover .cloud::before,
  body.dragover .cloud::after {
    box-shadow: 0 -10px 10px rgb(255 255 192 / 80%);
  }
}

body.leave-uninit .cloud {
  animation: dramatic-entry 1s;
}

body.uploading .cloud {
  animation: shake 2s infinite;
}

body.dragover .cloud {
  opacity: 0.8;
  transform: scale(1.5) rotate(10deg);
}

#file-container {
  position: absolute;
  top: -80%;
  left: -10%;
  margin: 0;
  padding: 0;
  width: 120%;
  height: 200%;
  overflow: hidden;
  z-index: 30;
}

#file-container input {
  position: absolute;
  appearance: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

#login-form {
  text-align: center;
  transition: .2 ease-in opacity, .2 ease-in display;
}

body:not(.auth-needed) #login-form {
  display: none;
  opacity: 0;
}

@media (width <= 520px) {
  #login-form {
    width: 200px;
    margin: 0 auto;
  }

  #login-form input {
    display: block;
    width: 200px;
    box-sizing: border-box;
    margin: 5px 0;
  }

  #login-form button {
    display: block;
    float: right;
    margin: 5px 0;
  }
}

#content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 150px 0 0 -400px;
  width: 800px;
  height: auto;
  overflow: visible;
}

@media (width <= 800px) {
  #content {
    left: 0;
    margin: 100px 0 0;
    width: 100%;
  }
}

#status {
  text-align: center;
  color: #666;
  opacity: 0;
  font-variant-numeric: tabular-nums;
  margin: 1em 10px;
  text-wrap: balance;
}

body:not(.auth-needed) #status {
  transition: .2s ease-in opacity;
  opacity: 1;
}

#header {
  opacity: 0;
  padding: 10px;
  display: none;
}

body:not(.auth-needed) #header {
  display: block;
  border-bottom: 0.5px solid #888;
  transition: .2s ease-in opacity, .2s ease-in display;
  opacity: 1;
}

#header::after {
  display: block;
  content: "";
  clear: both;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

.breadcrumb-folder {
  display: inline;
}

.breadcrumb-folder:not(:last-child)::after {
  content: " ⏵\00a0";
}

.breadcrumb a:not(:hover) {
  text-decoration: none;
  color: #008;
}

.breadcrumb-folder.current {
  font-weight: bold;
}

.breadcrumb-goto-form {
  display: inline;
}

.breadcrumb-goto-form details {
  display: inline-block;
}

.breadcrumb-goto-form details > summary {
  display: inline;
  list-style: none;
  cursor: pointer;
}

.breadcrumb-goto-form summary::-webkit-details-marker {
  font-size: 0;
}

.breadcrumb-goto-form details[open] > summary {
  display: none;
}

.breadcrumb-goto-form form {
  display: inline-block;
}

.breadcrumb-goto-form form * {
  margin-block: -50%;
}

.header-end {
  display: block;
  float: right;
}

.item-list {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 20px;
  list-style: none;
  opacity: 0;
  display: none;
}

body:not(.auth-needed) .item-list {
  transition: .2s ease-in opacity, .2s ease-in display;
  opacity: 1;
  display: block;
}

.item-list .file details {
  text-align: center;
  user-select: none;
  opacity: .7;
}

.item-list .file details > summary {
  display: inline;
  list-style: none;
  cursor: pointer;
}

.item-list .file summary::-webkit-details-marker {
  font-size: 0;
}

.item-list .item {
  padding: 10px;
  display: grid;
  grid-template-columns: 1em auto 1em;
  gap: 0 .5em;
}

.item-list .item:not(:last-child) {
  border-bottom: 0.5px solid #888;
}

.item-list .item .icon {
  width: 1.5em;
  display: inline-block;
  line-height: 1.5em;
}

.item-list .item .status-label {
  opacity: 0.8;
  font-variant-numeric: tabular-nums;
}

.item-list a {
  justify-self: start;
}

.item-list a:link:not(:hover) {
  text-decoration: none;
  color: #008;
}

.metadata {
  list-style: none;
  text-indent: 0;
  padding: 0;
  opacity: .7;
  grid-column: 2;
}

.actions {
  display: flex;
  justify-content: end;
  justify-items: end;
  margin-top: .5em;
  gap: .5em;
  grid-column: 2 / span 2;
}

.delete-item {
  color: #800;
}

.item-list .file details:not([open]) ~ .metadata,
.item-list .file details:not([open]) ~ .actions {
  display: none;
}

@media (width <= 800px) {
  .item-list {
    width: auto;
  }
}

.item-list li.pending-deletion {
  opacity: 0.5;
  text-decoration-line: line-through;
}

.item-list li:is(.pending-download, .downloaded) .download-item {
  display: none;
}

.item-list a:not([href]) ~ .actions > .save-item {
  display: none;
}

#push-subscription {
  position: absolute;
  top: 10px;
  right: 10px;
}

#experiment-mode {
  pointer-events: none;
  background: repeating-linear-gradient(
    45deg,
    rgb(255 224 62 / 50%), rgb(255 224 62 / 50%) 10px,
    rgb(0 0 0 / 50%) 10px, rgb(0 0 0 / 50%) 20px
  );
  background-repeat: repeat;
  top: 40%;
  left: 20%;
  position: absolute;
  width: 60%;
  z-index: 50;
  text-align: center;
  font-size: 40px;
  color: #fff;
  text-shadow: 0 0 3px black;
  transform: rotate(-13deg);
}

html:not(.experimental-features) #experiment-mode {
  display: none;
}

.drop-dialog[open] {
  border: none;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  padding: 0;
  border-radius: 5px;
  background: #f8f8f8;
}

.drop-dialog::backdrop {
  backdrop-filter: blur(5px);
}

.drop-dialog-title {
  --font-size: 1.3;
  --margin: calc( 1em / var(--font-size));

  margin: var(--margin) var(--margin) calc( .5em / var(--font-size)) var(--margin);
  font-size: calc(var(--font-size) * 1em);
}

.drop-dialog-content {
  width: 70vw;
  padding: .5em 1em;
}

@media (width <= 800px) {
  .drop-dialog-content {
    width: calc(100vw - 2em - 6px - 2em);
  }
}

.drop-dialog-content .option-title {
  font-weight: bold;
}

.drop-dialog-content .list-status {
  float: right;
  display: block;
  font-weight: normal;
}

.drop-dialog-content .option-title:first-child {
  margin-top: 0;
}

.drop-dialog-content .folder-selection {
  font-size: .8333rem;
  border: 1px solid #888;
  border-radius: 3px;
  display: grid;
  background: white;
}

.drop-dialog-content .summary-breadcrumb {
  padding: .5em 10px;
}

.drop-dialog-content .summary-item-list {
  width: 100%;
  height: 40vh;
  overflow: auto;
}

.drop-dialog-content .summary-item-list .item {
  padding: 4px 10px;
  border-bottom: none;
  grid-template-columns: 1em auto;
}

.drop-dialog-actions {
  padding: .5em 1em 1em;
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: .5em;
}

#change-file-dialog .filename {
  width: 100%;
  box-sizing: border-box;
}

.pop-image {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin: -80px -50px;
  width: 100px;
  z-index: 10;
  pointer-events: none;
  transition: .5s transform ease-in-out, .5s opacity ease-in-out;
  opacity: 0;
  transform: var(--un-pop);
  --pop-shift: -210px;
}

@media (width >= 520px) and (width <= 800px) {
  .pop-image {
    margin: -70px -37px;
    width: 75px;
    --pop-shift: -180px;
  }
}

@media (width <= 520px) {
  .pop-image {
    margin: -40px -25px;
    width: 50px;
    --pop-shift: -100px;
  }
}

body.pop .pop-image {
  opacity: 1;
  transform: var(--pop);
}

@media not all and (hover: none) {
  .cloud:hover ~ .pop-image {
    opacity: 1;
    transform: var(--pop);
  }
}
